<template>
  <div class="flex h-full w-screen justify-around p-10">
    <div>
      <h1 class="text-3xl text-center">Passwort ändern</h1>
      <form class="mt-10 w-80 text-left" @submit.prevent="updatePassword">
        <span class="font-serif text-gray-500">{{ email }}</span>
        <label class="mt-5 block text-sm font-medium text-gray-700">Neues Passwort</label>
        <InputText v-model="password" type="password" class="w-full" />
        <label class="mt-5 block text-sm font-medium text-gray-700">Passwort wiederholen</label>
        <InputText v-model="password2" type="password" class="w-full" />
        <button
          type="submit"
          :disabled="loading"
          class="submitButton mt-5 flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-400"
        >
          Passwort aktualisieren
        </button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
  import { supabase } from '@/supabase'
  import { useToast } from 'primevue/usetoast'
  import { defineComponent, onMounted, Ref, ref } from 'vue'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    setup() {
      let password: Ref<string> = ref('')
      let password2: Ref<string> = ref('')
      let email: Ref<string> = ref('')
      let localUser = ref()
      let loading = ref(false)
      const router = useRouter()
      const toast = useToast()

      const getUser = async () => {
        try {
          const { data } = await supabase.auth.getUser()
          localUser.value = data.user
          if (localUser.value?.email != undefined) {
            email.value = localUser.value?.email
          }
        } catch (error) {
          console.error(error)
        }
      }

      const updatePassword = async () => {
        if (password.value != password2.value) {
          toast.add({
            severity: 'error',
            summary: 'Passwort nicht gleich',
            detail: 'Die beiden Eingaben müssen übereinstimmen',
            life: 5000,
          })
          return
        }
        try {
          if (localUser.value) {
            loading.value = true
            const { data, error } = await supabase.auth.updateUser({
              password: password.value,
            })
            if (error) throw error
            localUser.value = data.user
            toast.add({
              severity: 'success',
              summary: 'Passwort geändert',
              detail: 'Ihr Passwort wurde erfolgreich geändert',
              life: 5000,
            })
            router.push('/')
          }
        } catch (error: any) {
          let text = error.message
          if (text == 'New password should be different from the old password.')
            text = 'Das neue Passwort muss sich von dem alten unterscheiden'
          if (text == 'Password should be at least 6 characters.')
            text = 'Das neue Passwort muss aus mindestens 6 Zeichen bestehen'
          toast.add({
            severity: 'error',
            summary: 'Passwort nicht geändert',
            detail: text,
            life: 10000,
          })
          console.error(error)
        } finally {
          loading.value = false
        }
      }

      onMounted(() => {
        getUser()
      })
      return {
        email,
        password,
        password2,
        updatePassword,
        loading,
      }
    },
  })
</script>

<style scoped>
  .submitButton {
    font-size: 1rem;
    background-color: var(--primary-color);
    color: #fff;
    &:hover {
      background-color: var(--secondary-color);
    }
  }
</style>
