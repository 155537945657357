import { defineStore } from 'pinia'
import { supabase } from '@/supabase'
import { Ref, ref } from 'vue'
import { Tables } from '@/types/database'

export const usePressStore = defineStore('press', () => {
  const pressStates: Ref<Tables<'press_state'>[]> = ref([])
  const pressStateTexts: Ref<Tables<'press_state_text'>[]> = ref([])
  const pressLevelTexts: Ref<Tables<'press_level_text'>[]> = ref([])
  const pressErrorTexts: Ref<Tables<'press_error_text'>[]> = ref([])
  let pressUpdates: any = null

  let initDone = new Promise(async (resolve) => {
    await fetchPressStateTexts()
    await fetchPressLevelTexts()
    await fetchPressErrorTexts()
    await fetchPressStates()
    resolve(null)
  })

  async function fetchPressStates() {
    try {
      const { data, error } = await supabase.from('press_state').select('*')
      if (error) throw error
      pressStates.value = data
    } catch (error) {
      console.error(error)
    }
  }

  async function fetchPressStateTexts() {
    try {
      const { data, error } = await supabase.from('press_state_text').select('*')
      if (error) throw error
      pressStateTexts.value = data
    } catch (error) {
      console.error(error)
    }
  }

  async function fetchPressLevelTexts() {
    try {
      const { data, error } = await supabase.from('press_level_text').select('*')
      if (error) throw error
      pressLevelTexts.value = data
    } catch (error) {
      console.error(error)
    }
  }

  async function fetchPressErrorTexts() {
    try {
      const { data, error } = await supabase.from('press_error_text').select('*')
      if (error) throw error
      pressErrorTexts.value = data
    } catch (error) {
      console.error(error)
    }
  }

  async function resetPressLevel(id: number): Promise<Error | null> {
    try {
      const { error } = await supabase.from('press_state').update({ level: 0 }).eq('asset_id', id)
      if (error) throw error
      return null
    } catch (error: any) {
      console.error(error)
      return error
    }
  }

  //TODO: add realtime update
  async function subscribeToPressChanges() {
    if (pressUpdates != null) return
    pressUpdates = supabase
      .channel('public:press_state')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'press_state' }, (payload) => {
        const newState = <Tables<'press_state'>>payload.new
        const index = pressStates.value.findIndex((p) => p.id === newState.id)
        if (index === -1) return
        pressStates.value[index] = newState
      })
      .subscribe()
  }

  async function unsubscribeFromPressChanges() {
    if (pressUpdates === null) return
    const result = await supabase.removeChannel(pressUpdates)
    if (result === 'ok') pressUpdates === null
  }

  const getPressStateByAssetId = (assetId: number) => pressStates.value.find((state) => state.asset_id === assetId)

  const pressStateTextByStateId = (stateId: number): string =>
    pressStateTexts.value.find((state) => state.state_id === stateId)?.state_text || 'ERROR - should not happen'
  //FIXME: throw error
  const pressStateTextByAssetId = (assetId: number): string => {
    const state = getPressStateByAssetId(assetId)
    const stateId = state?.state
    if (!stateId) return '- nicht vorhanden -'
    //FIXME: handle state with id 0
    if (stateId === 1)
      return (
        pressErrorTexts.value.find((err) => err.error_id === state.error)?.error_text || 'ERROR - should not happen'
      )
    return pressStateTexts.value.find((state) => state.state_id === stateId)?.state_text || 'ERROR - should not happen'
  }

  const pressLevelTextByLevelId = (levelId: number): string =>
    pressLevelTexts.value.find((state) => state.level_id === levelId)?.level_text || 'ERROR - should not happen'
  //FIXME: throw error
  const pressLevelTextByAssetId = (assetId: number): string => {
    const levelId = getPressStateByAssetId(assetId)?.level
    if (levelId === undefined) return '- nicht vorhanden -'
    return pressLevelTexts.value.find((state) => state.level_id === levelId)?.level_text || 'ERROR - should not happen'
  }

  return {
    pressStates,
    fetchPressStates,
    subscribeToPressChanges,
    resetPressLevel,
    unsubscribeFromPressChanges,
    getPressStateByAssetId,
    pressStateTextByStateId,
    pressStateTextByAssetId,
    pressLevelTextByAssetId,
    initDone,
  }
})
