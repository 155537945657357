import { supabase } from '@/supabase'
import { defineStore } from 'pinia'
import { ref, Ref } from 'vue'
import { TreeNode } from 'primevue/treenode'
import { useRightsStore } from './rights'
import { useAuthStore } from './auth'
import { Tables } from '@/types/database'

export const useBranchOfficeStore = defineStore('branchOffice', () => {
  const branchOffices = ref()
  const branchMap: Ref<Map<number, Tables<'branch_office'>>> = ref(new Map())
  const branchOfficesAsTree: Ref<TreeNode[]> = ref([])
  const rightsStore = useRightsStore()
  const authStore = useAuthStore()

  async function fetchBranchOffices() {
    try {
      const { data, error } = await supabase.from('branch_office').select('*')
      if (error) throw error
      branchOffices.value = data
      branchOfficesAsTree.value = buildTree(data)
    } catch (error: any) {
      console.error(error)
    }
  }

  ;(async () => {
    await rightsStore.initDone
    fetchBranchOffices()
  })()

  function buildTree(list: Tables<'branch_office'>[]): TreeNode[] {
    branchMap.value = new Map()
    for (const item of list) {
      branchMap.value.set(item.id, item)
    }
    const right = rightsStore.rights.find((r) => r.user_id === authStore.user?.id)
    if (!right) return []
    const init = right.assigned_branch_offices.map((b) => branchMap.value.get(b)).filter((b) => b != undefined)
    return init.map((b) => {
      return {
        label: b.label ?? 'Ohne Namen',
        key: b.id.toString(),
        children: genChild(b.child_ids),
        data: b,
      }
    })
  }

  function genChild(childs: number[]): TreeNode[] {
    if (childs.length === 0) return []
    const nodes: TreeNode[] = []
    for (const childId of childs) {
      let child = branchMap.value.get(childId)!
      nodes.push({
        label: child.label ?? 'Ohne Namen',
        key: child.id.toString(),
        children: genChild(child.child_ids),
        data: child,
      })
    }
    return nodes
  }

  function getBranchById(id: number | undefined) {
    if (id === undefined) return undefined
    return branchMap.value.get(id)
  }
  function initBranchesByRights(nodeIds: number[]) {
    const nodes = nodeIds.map((b) => branchMap.value.get(b)).filter((b) => b != undefined)
    return nodes.map((b) => {
      return {
        label: b.label ?? 'Ohne Namen',
        key: b.id.toString(),
        children: genChild(b.child_ids),
        data: b,
      }
    })
  }

  function getTree() {
    return branchOfficesAsTree.value
  }
  return { branchOffices, fetchBranchOffices, branchOfficesAsTree, getBranchById, initBranchesByRights, getTree }
})
