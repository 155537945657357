import { supabase } from '@/supabase'
import { Tables } from '@/types/database'
import { User } from '@supabase/supabase-js'
import { defineStore } from 'pinia'
import { computed, Ref, ref } from 'vue'
import { usePersonStore } from './dispo/person'

export const useAuthStore = defineStore('auth', () => {
  const personStore = usePersonStore()

  const user: Ref<User | null> = ref(null)
  const person: Ref<Tables<'person'> | undefined> = computed(() => personStore.currentUser ?? undefined)

  ;(async () => {
    user.value = (await supabase.auth.getUser()).data.user
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        if (session) {
          user.value = session.user
        }
      } else if (event === 'SIGNED_OUT') {
        user.value = null
      }
    })
  })()

  return { user, person }
})
