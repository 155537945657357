<template>
  <div class="flex h-full flex-col-reverse sm:flex-col">
    <Navigation
      class="noPrint"
      v-if="!isSmallScreen && authenticated"
      :navMain="navigation"
      :isAuthenticated="authenticated"
      :navUser="userNavigation"
      :isExpanded="isDrawerExpanded"
      @update:isExpanded="isDrawerExpanded = $event"
      @showFeedbackForm="(n: any) => (showFeedbackForm = n)"
      :signout="signout"
    />

    <div
      id="view"
      :class="{ expanded: isDrawerExpanded, mobile: isSmallScreen, 'no-sidebar': !authenticated || isSmallScreen }"
    >
      <Suspense>
        <template #default>
          <div class="h-full">
            <router-view></router-view>
            <teleport v-if="showFeedbackForm" to="body">
              <div class="absolute bottom-0 left-0 z-[1000] h-full w-full bg-gray-800 sm:top-0 sm:p-8">
                <Feedback @cancel="showFeedbackForm = false"></Feedback>
              </div>
            </teleport>
          </div>
        </template>
        <template #fallback> loading ... </template>
      </Suspense>
    </div>
  </div>
  <Toast />
  <DynamicDialog />
  <ConfirmDialog />
</template>

<script setup lang="ts">
  import { computed, Ref, ref, watch } from 'vue'
  import { supabase } from './supabase'
  import { useRouter } from 'vue-router'
  import Feedback from './components/Feedback.vue'
  import Navigation from './components/Navigation.vue'
  import Toast from 'primevue/toast'
  import { useUserDeviceStore } from './store/userDevice'
  import DynamicDialog from 'primevue/dynamicdialog'
  import { useRightsStore } from './store/rights'
  import { useDeviceStore } from './store/device'
  import { useBranchOfficeStore } from './store/branchOffice'
  import { useDeviceGroupStore } from './store/deviceGroup'
  import { useAuthStore } from './store/auth'
  import { usePressStore } from './store/press'
  import { ConfirmDialog } from 'primevue'
  import { usePropertyStore } from './store/property'

  const router = useRouter()
  const showFeedbackForm = ref(false)
  const rightsStore = useRightsStore()
  const deviceStore = useDeviceStore()
  const pressStore = usePressStore()
  const branchOfficeStore = useBranchOfficeStore()
  const groupStore = useDeviceGroupStore()
  const authStore = useAuthStore()
  const authenticated = computed(() => authStore.user)
  const isDrawerExpanded = ref(false)

  const isSmallScreen = computed(() => useUserDeviceStore().isSmallScreen)

  watch(
    authenticated,
    () => {
      if (authenticated.value) {
        rightsStore.refetch()
        deviceStore.fetchDevices()
        deviceStore.subscribeToDeviceChanges()
        pressStore.subscribeToPressChanges()
      } else {
        deviceStore.unsubscribeFromDeviceChanges()
        pressStore.unsubscribeFromPressChanges()
      }
    },
    { immediate: true }
  )

  //PUT EVERYTHING TO LOAD AFTER FETCHING RIGHTS HERE:
  watch(
    rightsStore,
    () => {
      deviceStore.fetchDevices()
      branchOfficeStore.fetchBranchOffices()
      groupStore.fetchDeviceGroups()
    },
    { immediate: true }
  )

  const navigation = computed(() => {
    if (!authenticated.value) {
      return []
    }

    let navItems = [
      {
        name: 'Dashboard',
        path: '/',
        icon: 'dashboard.png',
        showInNav: true,
      },
      {
        name: 'Kartenansicht',
        path: '/live',
        icon: 'live_ortung.png',
        showInNav: rightsStore.isAllowed('READ', 'DEVICE'),
      },
      // { name: 'Live Ortung', path: '/replay', showInNav: false, current: false },
      {
        name: 'Lohnplausibilisierung',
        path: '/salaryReview',
        icon: 'baustellen.png',
        showInNav: rightsStore.isAllowed('READ', 'SALARYREVIEW'),
      },
      {
        name: 'Listenansicht',
        path: '/inventory',
        icon: 'planungsuebersicht.png',
        showInNav: rightsStore.isAllowed('READ', 'INVENTORY'),
      },
      {
        name: 'Gerät anlegen',
        path: '/devices/create',
        icon: 'geraete.png',
        showInNav: rightsStore.isAllowed('CREATE', 'DEVICE'),
      },
      {
        name: 'Kundenverwaltung',
        path: '/customer',
        icon: 'einstellungen.png',
        showInNav: rightsStore.isAllowed('READ', 'CUSTOMER'),
      },
      {
        name: 'Rechteverwaltung',
        path: '/rights',
        icon: 'einstellungen.png',
        showInNav: rightsStore.isAllowed('READ', 'RIGHTS'),
      },
      {
        name: 'Bestellungen',
        path: '/orders',
        icon: 'bestellungen.png',
        showInNav: rightsStore.isAllowed('READ', 'SHOP'),
      },
      {
        name: 'Baustellen',
        path: '/mySites',
        icon: 'baustellen.png',
        showInNav: rightsStore.isAllowed('READ', 'SITES.OWN'),
      },
      {
        name: 'Disposition',
        path: '/dispatch',
        icon: 'disposition.png',
        showInNav: rightsStore.isAllowed('READ', 'DISPATCH'),
      },
    ]

    return navItems.filter((n) => n.showInNav)
  })

  // Check on resize
  window.addEventListener('resize', function () {
    useUserDeviceStore().updateSmallScreen()
  })

  const signout = () => {
    supabase.auth.signOut()
  }

  const userNavigation = [
    {
      name: 'Passwort ändern',
      action: () => {
        router.push({ path: '/changePassword' })
      },
    },
    // {
    //   name: 'Ausloggen',
    //   action: () => {
    //     signout()
    //   },
    // },
  ]
</script>

<style lang="scss" scoped>
  #view {
    height: 100%;
    transition: width 0.3s, margin-left 0.3s;

    &.expanded {
      width: calc(100% - 15.625rem);
      margin-left: 15.625rem;
    }

    &:not(.expanded) {
      width: calc(100% - 3.75rem);
      margin-left: 3.75rem;
    }

    &.mobile,
    &.no-sidebar {
      width: 100%;
      margin-left: 0;
    }
  }
</style>
