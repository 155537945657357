import { formatDateYYYYMMDD } from '@/helper/date'

export {}

declare global {
  interface Date {
    formatDateYYYYMMDD(): string
  }
}

Date.prototype.formatDateYYYYMMDD = function (): string {
  return formatDateYYYYMMDD(this)
}
