<template>
  <nav :class="['flex h-full flex-col-reverse justify-start border-0 bg-primary-300', { expanded: isExpanded }]">
    <div class="flex h-full flex-col justify-between">
      <div class="mt-4 flex items-center justify-center px-2">
        <img v-if="!isExpanded" src="@/assets/sidebar/logo_small.png" alt="Logo" class="h-auto" />
        <img v-else src="@/assets/sidebar/logo_expanded.png" alt="Logo" class="h-auto" />
      </div>

      <div class="relative flex h-full flex-col justify-between">
        <div class="mt-8 flex flex-1 flex-col px-2">
          <router-link
            v-for="item in navMain"
            :key="item.name"
            :class="[
              'primary mb-1 flex h-11 items-center rounded border border-transparent bg-primary-300 py-2 text-sm font-normal leading-4 text-neutral-200',
              isExpanded ? 'justify-start px-lg' : 'justify-center',
              $route.path === item.path
                ? 'border-opacity-light-300 bg-primary-600'
                : 'hover:border-opacity-light-300 hover:bg-primary-400',
            ]"
            :to="item.path"
          >
            <img :src="getIconPath(item.icon)" alt="icon" class="h-4 w-4" />
            <span v-if="isExpanded" class="ml-4 overflow-hidden whitespace-nowrap transition-all duration-300">
              {{ item.name }}
            </span>
          </router-link>
        </div>

        <!-- Profile dropdown -->
        <div
          v-if="isAuthenticated"
          class="flex flex-col px-2 sm:static sm:inset-auto sm:ml-0"
          :class="[isExpanded ? 'justify-start' : 'justify-center']"
        >
          <!-- <button
            type="button"
            class="primary relative inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800"
            @click="$emit('showFeedbackForm', true)"
          >
            <span>Feedback</span>
          </button> -->
          <button
            class="primary mb-2 flex h-11 items-center rounded py-2 text-sm font-normal leading-4 text-neutral-200"
            :class="[
              isExpanded ? 'justify-start px-4' : 'justify-center',
              'hover:border hover:border-opacity-light-300 hover:bg-primary-400',
              'w-full border border-transparent hover:border-opacity-50',
            ]"
            @click="confirmLogout"
          >
            <img src="@/assets/sidebar/ausloggen.png" alt="logout icon" class="h-4 w-4" />
            <span v-if="isExpanded" class="ml-4 overflow-hidden whitespace-nowrap transition-all duration-300"
              >Ausloggen</span
            >
          </button>

          <Menu as="div" class="relative flex w-full items-center">
            <MenuButton
              :class="[
                'primary flex items-center rounded border border-transparent bg-primary-300 text-sm font-normal text-white hover:border-opacity-light-300 hover:bg-primary-400',
                isExpanded ? 'w-full justify-start px-lg' : 'w-full justify-center',
              ]"
            >
              <span class="sr-only">Open user menu</span>
              <img class="h-8 w-8 rounded-full bg-gray-500" src="@/assets/dummy.png" alt="" />
              <span
                v-if="isExpanded"
                class="transtition-all ml-4 overflow-hidden whitespace-nowrap text-sm font-medium text-neutral-200 duration-200"
              >
                {{
                  personStore.currentUser
                    ? `${personStore.currentUser.first_name} ${personStore.currentUser.last_name}`
                    : 'Unable to fetch name'
                }}
              </span>
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute bottom-10 left-0 z-[1000] w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:bottom-auto sm:mt-2"
              >
                <MenuItem v-for="item in navUser" :key="item.name" v-slot="{ active }">
                  <button
                    :class="[active ? 'active' : '']"
                    class="primary block w-full cursor-pointer border-b border-gray-300 px-4 py-2 text-left text-sm last:border-b-0"
                    @click="item.action"
                  >
                    <span class="block">{{ item.name }}</span>
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>

      <div class="mx-2 mb-[9px] mt-4 h-px w-[calc(100%-16px)] bg-neutral-300"></div>

      <button class="mx-2 mb-2 flex h-10 items-center justify-center py-2 align-middle" @click="toggleDrawer">
        <img v-if="isExpanded" src="@/assets/sidebar/angle-left.png" alt="Toggle" />
        <img v-else src="@/assets/sidebar/angle-right.png" alt="Toggle" />
      </button>
    </div>
  </nav>
</template>

<script setup>
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
  import { usePersonStore } from '@/store/dispo/person'
  import { useConfirm } from 'primevue'

  const props = defineProps({
    navMain: {
      type: Array,
    },
    navUser: {
      type: Array,
    },
    isAuthenticated: false,
    showFeedbackForm: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    signout: {
      type: Function,
      required: true,
    },
  })

  const emit = defineEmits(['update:isExpanded', 'showFeedbackForm'])

  const toggleDrawer = () => {
    emit('update:isExpanded', !props.isExpanded)
  }

  const icons = import.meta.glob('../assets/sidebar/*.png', { eager: true })

  const getIconPath = (iconName) => {
    const iconPath = `../assets/sidebar/${iconName}`
    const resolvedPath = icons[iconPath]?.default
    if (!resolvedPath) {
      console.error(`Icon not found for path: ${iconPath}`)
    }
    return resolvedPath || '../assets/dummy.png'
  }

  const personStore = usePersonStore()
  personStore.fetchCurrentUser()

  const confirm = useConfirm()

  const confirmLogout = () => {
    confirm.require({
      message: 'Möchten Sie sich abmelden?',
      header: 'Logout bestätigen',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Logout',
      rejectLabel: 'Cancel',
      accept: () => {
        props.signout()
      },
      reject: () => {
        console.log('Logout abgebrochen')
      },
      class: 'bg-white p-4 rounded-lg',
      acceptClass: 'px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600',
      rejectClass: 'px-4 py-2 bg-gray-300 rounded hover:bg-gray-400',
      headerClass: 'text-lg font-semibold mb-4',
      messageClass: 'mb-4',
    })
  }
</script>

<style lang="scss" scoped>
  nav {
    position: fixed;
    height: 100%;
    width: 3.75rem;
    z-index: 999;
    transition: width 0.3s;

    &.expanded {
      width: 15.625rem;
    }
  }
  .router-link {
    border-width: 1px;
    border-color: var(--opacity-light-color-300);
    height: 2.75rem;
    width: 100%;
    padding-left: var(--lg);
    padding-right: var(--lg);
    display: flex;
    align-items: center;
    justify-content: center;

    &.expanded {
      justify-content: flex-start;
    }
  }
</style>
