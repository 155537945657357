export default {
  root: ({ props, context, parent }) => ({
    class: [
      // Font
      {
        'text-16': props.size == 'large',
        'text-14': props.size == null,
      },

      // Flex
      { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },

      // Spacing
      'm-0',
      { 'w-full': props.fluid },

      // Size
      {
        'py-lg px-base': props.size == 'large',
        'py-1.5 px-2': props.size == 'small',
        'py-2 px-3': props.size == null,
      },

      // Shape
      { 'rounded-sf-base': parent.instance.$name !== 'InputGroup' },
      {
        'first:rounded-l-sf-base rounded-none last:rounded-r-sf-base': parent.instance.$name == 'InputGroup',
      },
      { 'border-0 border-y first:border-l last:border-r': parent.instance.$name == 'InputGroup' },
      { 'first:ml-0 -ml-px': parent.instance.$name == 'InputGroup' && !props.showButtons },

      // Colors
      'text-primary-600',
      'placeholder:text-neutral-600',
      { 'bg-neutral-50': !context.disabled },
      'border',
      { 'border-primary-600': !props.invalid },

      // Invalid State
      'invalid:focus:ring-danger-400',
      'invalid:hover:border-danger-500',
      { 'border-danger-400': props.invalid },

      // States
      {
        'hover:border-secondary-300': !context.disabled && !props.invalid,
        // 'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 focus:z-10':
        //   !context.disabled,
        'bg-neutral-200 text-neutral-700 placeholder:text-neutral-400 border-transparent select-none pointer-events-none cursor-default':
          context.disabled,
      },

      // DatePicker
      {
        // 'border-0 rounded-l-none':
        'pl-[52px]':
          parent.instance.$name == 'DatePicker' && parent.instance.showIcon && parent.instance.iconDisplay === 'input',
      },

      // Filled State *for FloatLabel
      {
        filled:
          (parent.instance?.$name == 'FloatLabel' && context.filled) ||
          (parent.instance?.$parentInstance?.$name == 'FloatLabel' &&
            parent.props.modelValue !== null &&
            parent.props.modelValue?.length !== 0),
      },

      // Misc
      'appearance-none',
      'transition-colors duration-200',
    ],
  }),
}
