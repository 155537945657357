export default {
  root: {
    class: [
      // Flex
      'flex items-center justify-center',

      // Shape
      'first:rounded-l-sf-base',
      'last:rounded-r-sf-base',
      'border-y',

      'last:border-r first:border-l border-r-0 border-l-0',

      // Space
      'p-sm pr-0',

      // Size
      'min-w-[2.5rem]',

      // Color
      'bg-neutral-50',
      'text-primary-500',
      'border-primary-600',
    ],
  },
}
