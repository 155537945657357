import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import ChangePassword from '@/views/ChangePassword.vue'
import Login from '@/views/Login.vue'
import DeviceNav from '@/views/devices/Nav.vue'
import { supabase } from '@/supabase'
import { useRightsStore } from '@/store/rights'

const LiveTracking = () => import('@/components/LiveTracking.vue')
const Replay = () => import('@/components/Replay.vue')
const SalaryReview = () => import('@/components/SalaryReview.vue')
const DeviceCreate = () => import('@/views/devices/Create.vue')
const DeviceView = () => import('@/views/devices/View.vue')
const DeviceEdit = () => import('@/views/devices/Edit.vue')
const InventoryView = () => import('@/views/inventory/View.vue')
const RightView = () => import('@/views/rights/View.vue')
const CustomerView = () => import('@/views/customer/View.vue')
const TestView = () => import('@/views/Test.vue')
const DispatchView = () => import('@/views/dispo/dispatch/View.vue')
const DashboardView = () => import('@/views/dashboard/View.vue')
const OrderView = () => import('@/views/dispo/order/View.vue')
const SiteView = () => import('@/views/dispo/site/View.vue')
const DirvingOrder = () => import('@/views/dispo/dispatch/DrivingOrder.vue')

const routes: RouteRecordRaw[] = [
  { path: '/login', component: Login },
  { path: '/changePassword', component: ChangePassword },
  { path: '/', component: DashboardView },
  { path: '/live', component: LiveTracking },
  { path: '/replay', component: Replay },
  { path: '/replay/:deviceId', component: Replay },
  { path: '/replay/:deviceId/:from', component: Replay },
  { path: '/replay/:deviceId/:from/:to', component: Replay },
  { path: '/salaryReview', component: SalaryReview },
  { path: '/salaryReview/:deviceId', component: SalaryReview },
  { path: '/devices', component: DeviceNav },
  { path: '/devices/create', component: DeviceCreate },
  { path: '/devices/:id', component: DeviceView, props: true },
  { path: '/devices/:id/edit', component: DeviceEdit, props: true },
  { path: '/inventory', component: InventoryView, props: true },
  { path: '/rights', component: RightView },
  { path: '/customer', component: CustomerView },
  { path: '/test', component: TestView },
  { path: '/orders', component: OrderView },
  { path: '/dispatch', component: DispatchView },
  { path: '/mySites', component: SiteView },
  { path: '/dispatch/driving-order/:date', component: DirvingOrder, props: true },
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

const canAccess = async (to: any): Promise<boolean> => {
  const rightsStore = useRightsStore()
  await rightsStore.initDone
  if (to.fullPath === '/live') {
    return rightsStore.isAllowed('READ', 'DEVICE')
  } else if (to.fullPath.startsWith('/replay')) {
    return rightsStore.isAllowed('READ', 'DEVICE.HISTORY')
  } else if (to.fullPath.startsWith('/salaryReview')) {
    return rightsStore.isAllowed('READ', 'SALARYREVIEW')
  } else if (to.fullPath === '/devices/create') {
    return rightsStore.isAllowed('CREATE', 'DEVICE')
  } else if (to.fullPath.startsWith('/devices')) {
    return rightsStore.isAllowed('READ', 'DEVICE')
  } else if (to.fullPath === '/inventory') {
    return rightsStore.isAllowed('READ', 'INVENTORY')
  } else if (to.fullPath === '/rights') {
    return rightsStore.isAllowed('READ', 'RIGHTS')
  } else if (to.fullPath === '/customer') {
    return rightsStore.isAllowed('READ', 'CUSTOMER')
  } else if (to.fullPath === '/test') {
    return rightsStore.isAllowed('READ', 'DEV')
  } else if (to.fullPath === '/orders') {
    return rightsStore.isAllowed('READ', 'ORDERS') || rightsStore.isAllowed('READ', 'ORDERS.OWN')
  } else if (to.fullPath.startsWith('/dispatch')) {
    return rightsStore.isAllowed('READ', 'DISPATCH')
  } else if (to.fullPath === '/mySites') {
    return rightsStore.isAllowed('READ', 'SITES.OWN') || rightsStore.isAllowed('READ', 'SITES')
  }
  return true
}

router.beforeEach(async (to, from, next) => {
  const user = (await supabase.auth.getUser())?.data.user
  if (to.fullPath.includes('token')) {
    const splittedPath = to.fullPath.substring(1).split('&')
    console.log(splittedPath)
    let token_type = ''
    let access_token = ''
    let token_hash = ''
    let type = ''
    for (const subPath of splittedPath) {
      if (subPath.split('=')[0] === 'access_token') access_token = subPath.split('=')[1]
      if (subPath.split('=')[0] === '?token_hash') token_hash = subPath.split('=')[1]
      if (subPath.split('=')[0] === 'token_type') token_type = subPath.split('=')[1]
      if (subPath.split('=')[0] === 'type') type = subPath.split('=')[1]
    }
    await supabase.auth.verifyOtp({ token_hash, type: 'email' })
    if (type === 'recovery') {
      next('/changePassword?t=recovery')
    } else if (type === 'invite') {
      next('/changePassword?t=invite')
    }
    next('/')
  } else if (user?.aud.includes('authenticated')) {
    const hasAccess = await canAccess(to)
    if (hasAccess) {
      next()
    } else {
      console.error('No access to ', to.fullPath)
      next('/') //FIXME: error page
    }
  } else if (to.fullPath.includes('login')) {
    next()
  } else {
    next('/login')
  }
})
