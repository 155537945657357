import { supabase } from '@/supabase'
import { Tables } from '@/types/database'
import { get, padEnd } from 'lodash'
import { defineStore } from 'pinia'
import { computed, Ref, ref } from 'vue'

export const usePersonStore = defineStore('person', () => {
  const people: Ref<Tables<'person'>[]> = ref([])
  const currentUser: Ref<Tables<'person'> | null> = ref(null)

  async function fetchPeople() {
    try {
      const { data, error } = await supabase.from('person').select('*')
      if (error) throw error
      people.value = data
    } catch (error) {
      console.error('Error fetching people:', error)
    }
  }

  async function fetchCurrentUser() {
    try {
      await fetchPeople()

      const { data: authData, error } = await supabase.auth.getUser()
      if (error || !authData?.user) {
        console.warn('There was an issue while trying to fetch the user.')
        return
      }

      const userUuid = authData.user.id

      currentUser.value = people.value.find((p) => p.uuid === userUuid) || null

      if (!currentUser.value) {
        console.warn('User not found in the person table.')
      }
    } catch (error) {
      console.error('Error fetching current user:', error)
    }
  }

  const getPerson = (id: string) => {
    const person = people.value.find((p) => p.uuid === id)
    if (!person) return 'UNBEKANNT'
    return `${person.first_name} ${person.last_name}`
  }

  return { people, getPerson, currentUser, fetchCurrentUser }
})
