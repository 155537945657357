export {}

declare global {
  interface Array<T> {
    unique(): Array<T>
  }
}

Array.prototype.unique = function <T>(): Array<T> {
  return Array.from(new Set<T>(this))
}
