import { getPersonName } from '@/helper/formatter'
import { supabase } from '@/supabase'
import { Tables } from '@/types/database'
import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'

export const useRightsStore = defineStore('rights', () => {
  let roles: Ref<Tables<'rights_role'>[]> = ref([])
  let persons: Ref<Tables<'person'>[]> = ref([])
  let rights: Ref<Tables<'rights_user_role'>[]> = ref([])
  const userRights: Ref<Tables<'rights_access_control_list'>[]> = ref([])
  const userId: Ref<string> = ref('')
  let personWithRights: Ref<PersonWithRights[]> = ref([])

  type PersonWithRights = {
    user_id: string
    user: string
    role: {
      id: number
      role: string
    }
    assignedBranches: number[]
    assignedGroups: number[]
  }

  let initDone = new Promise(async (resolve) => {
    await refetch()
    resolve(null)
  })

  async function refetch() {
    await fetchRoles()
    await fetchPersons()
    await fetchRights()
    const fetchedId = (await supabase.auth.getUser())?.data.user?.id
    userId.value = fetchedId ?? ''
    const roleId = rights.value.find((r) => r.user_id === userId.value)?.role_id
    if (roleId === undefined) return
    await fetchacl(roleId)
    if (isAllowed('READ', 'RIGHTS')) joinTables()
  }

  supabase.auth.onAuthStateChange((state) => {
    if (!state.localeCompare('SIGNED_OUT')) {
      persons.value = []
      rights.value = []
      personWithRights.value = []
    }
  })

  async function fetchRoles() {
    try {
      const { data, error } = await supabase.from('rights_role').select('*').neq('id', 10000).neq('id', 11000)
      if (error) throw error
      if (data) roles.value = data
    } catch (error: any) {
      console.error(error)
    }
  }

  async function fetchPersons() {
    try {
      const { data, error } = await supabase.from('person').select('*').eq('is_visible', true)
      if (error) throw error
      if (data) persons.value = data
    } catch (error: any) {
      console.error(error)
    }
  }

  async function fetchRights() {
    try {
      const { data, error } = await supabase.from('rights_user_role').select('*')
      if (error) throw error
      if (data) rights.value = data
    } catch (error: any) {
      console.error(error)
    }
  }

  async function fetchacl(role: number) {
    try {
      const { data, error } = await supabase.from('rights_access_control_list').select('*').eq('role_id', role)
      if (error) throw error
      userRights.value = data
    } catch (error) {
      console.error(error)
    }
  }

  function joinTables() {
    personWithRights.value = []
    for (const person of persons.value) {
      if (!person.uuid) continue
      const userRights = rights.value.find((r) => r.user_id === person.uuid)
      if (!userRights) continue
      const role = roles.value.find((r) => r.id === userRights.role_id)!
      const newEntry: PersonWithRights = {
        user_id: person.uuid,
        user: getPersonName(person),
        role: role,
        assignedBranches: userRights.assigned_branch_offices,
        assignedGroups: userRights.assigned_groups,
      } //TODO
      personWithRights.value.push(newEntry)
    }
  }

  //TODO: Bulkupsert to reduce time
  async function updateRights(newEntries: PersonWithRights[]) {
    for (const newEntry of newEntries) {
      try {
        const entryForSupabase: Omit<Tables<'rights_user_role'>, 'id' | 'assigned_group' | 'assigned_branch_office'> = {
          role_id: newEntry.role.id,
          user_id: newEntry.user_id,
          assigned_branch_offices: newEntry.assignedBranches,
          assigned_groups: newEntry.assignedGroups,
        }
        const { data, error } = await supabase
          .from('rights_user_role')
          .update(entryForSupabase)
          .eq('user_id', newEntry.user_id)
          .select()
          .single()
        if (error) throw error
        const person = personWithRights.value.find((p) => p.user_id === data.user_id)
        if (!person) return
        person.assignedGroups = newEntry.assignedGroups
        person.assignedBranches = newEntry.assignedBranches
        person.role = newEntry.role
      } catch (error: any) {
        console.error(error)
      }
    }
  }

  type CRUD = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE'

  type FEATURE =
    | 'BRANCH'
    | 'CUSTOMER'
    | 'DEVICE'
    | 'DEVICE.HISTORY'
    | 'INACTIVE'
    | 'INVENTORY'
    | 'OPERATINGHOURS'
    | 'RIGHTS'
    | 'SALARYREVIEW'
    | 'DEVICE.PDF'
    | 'SHOP'
    | 'ORDERS'
    | 'ORDERS.OWN'
    | 'DISPATCH'
    | 'SITES'
    | 'SITES.OWN'
    | 'DEV'

  function isAllowed(method: CRUD, feature: FEATURE) {
    const right = userRights.value.find((r) => r.name === feature)
    if (!right) return false
    switch (method) {
      case 'CREATE':
        return right.create
      case 'READ':
        return right.read
      case 'UPDATE':
        return right.update
      case 'DELETE':
        return right.delete
      default:
        return false
    }
  }

  function isSelf(id: string) {
    return !userId.value.localeCompare(id)
  }

  return {
    roles,
    persons,
    rights,
    personWithRights,
    userRights,
    initDone,
    fetchRoles,
    fetchPersons,
    fetchRights,
    updateRights,
    refetch,
    isAllowed,
    isSelf,
  }
})
