export default {
  root: ({ props, state }) => ({
    class: [
      // Display and Position
      'inline-flex',
      'relative',

      // Shape
      'rounded-sf-sm',
      { '!rounded-sf-base': props.size === 'large' },

      'text-14',
      { '!text-16 py-sm': props.size === 'large' },

      // Color and Background
      { 'bg-neutral-50': !props.disabled },
      'border',
      { 'border-primary-600': !props.invalid },

      // Invalid State
      'invalid:focus:ring-danger-300',
      'invalid:hover:border-danger-500',
      { 'border-danger-400': props.invalid },

      // Transitions
      'transition-all',
      'duration-200',

      // States
      { 'hover:border-secondary-300': !props.invalid },
      { 'outline-none outline-offset-0 ring-1 ring-primary-500 dark:ring-primary-400 z-10': state.focused },

      // Misc
      'cursor-pointer',
      'select-none',
      { 'bg-neutral-200 select-none pointer-events-none cursor-default border-0': props.disabled },
    ],
  }),
  clearIcon: ({ props }) => ({
    class: [
      // Flexbox
      'flex items-center justify-center my-auto',

      // Color and Background
      'text-neutral-600',
      'hover:text-neutral-800',
      'focus:text-neutral-800',
      'active:text-neutral-800',

      // Size
      'min-w-[10px]',
    ],
  }),
  labelContainer: {
    class: ['overflow-hidden flex flex-auto cursor-pointer'],
  },
  label: ({ props, parent }) => ({
    class: [
      'block leading-[normal]',

      // Space
      'py-2 px-3',

      // Color
      'text-primary-600',
      'placeholder:!text-neutral-600',
      {
        'placeholder:text-neutral-600': parent.instance?.$name == 'FloatLabel',
        '!text-neutral-600':
          (parent.instance?.$name == 'FloatLabel' && props.modelValue == null) || props.modelValue?.length == 0,
      },

      // Filled State *for FloatLabel
      { filled: parent.instance?.$name == 'FloatLabel' && props.modelValue !== null },

      // Transition
      'transition duration-200',

      // Misc
      'overflow-hidden whitespace-nowrap cursor-pointer overflow-ellipsis',
    ],
  }),
  dropdown: ({ props }) => ({
    class: [
      // Flexbox
      'flex items-center justify-center',
      'shrink-0',

      // Color and Background
      'bg-transparent',
      'text-primary-600',

      // Size
      'w-12',

      // Shape
      'rounded-sf-sm',
      { '!rounded-sf-base': props.size === 'large' },
    ],
  }),
  panel: ({ props }) => ({
    class: [
      // Colors
      'bg-neutral-50',
      'text-primary-600',

      // Shape
      'border border-neutral-300',
      'rounded-sf-sm',
      { '!rounded-sf-base': props.size === 'large' },
      'shadow-md',
      ' overflow-hidden',
    ],
  }),
  treeContainer: {
    class: [
      // Sizing
      'max-h-[200px]',

      // Misc
      'overflow-auto',
    ],
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
}
